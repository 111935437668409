<template>
    <div>
        <el-dialog v-model="dialogVisible" :title="title" :width="width" :before-close="handleClose">
            <slot name="message">
                <div>
                    <span>{{ message }}</span>
                </div>
            </slot>
            <template #footer>
                <span class="dialog-footer">
                    <slot name="footer"></slot>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'tanDialog',
    inject: ['reload'], //刷新引用
    props: ['DialogVisible', 'Title', 'Width', 'Message'],
    data() {
        return {
            dialogVisible: '',
            title: '',
            width: '',
            message: '',

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
        }
    },
    watch: {
        DialogVisible: {
            handler(newVal) {
                this.dialogVisible = newVal
            },
            deep: true,
            immediate: true
        },
        Title: {
            handler(newVal) {
                this.title = newVal
            },
            deep: true,
            immediate: true
        },
        Width: {
            handler(newVal) {
                this.width = newVal
            },
            deep: true,
            immediate: true
        },
        Message: {
            handler(newVal) {
                this.message = newVal
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        // 选中
        handleClose(done) {
            this.$emit('before-close', done)
        }
    },
    created() {},
    components: {}
}
</script>

<style scoped></style>
