<template>
    <div class="my-cascader-box">
        <div class="my-cascader">
            <el-cascader ref="cascaderRef" style="width: 100%;" v-model="modelvalue" :options="options" :props="props"
                :placeholder="placeholder" :show-all-levels="showalllevels" :collapse-tags="collapsetags"
                :collapse-tags-tooltip="collapsetagstooltip" clearable @change="changeEvent" />
        </div>
        <div class="my-cascader-icon" v-if="collapsetagstooltip == true" @click="clickIconEvent(collapsetags)">
            <i class="el-icon-view" v-if="collapsetags"></i>
            <i class="el-icon-dish" v-else></i>
        </div>
    </div>
</template>

<script>

import areacode from '@/assets/json/areacode.json'

export default {
    name: "cityCodeCascaderBox",
    inject: ["reload"], //刷新引用
    props: ['ModelValue', 'Placeholder', 'ShowAllLevels', 'CollapseTags', 'CollapseTagsTooltip','CheckStrictly','EmitPath'],
    data() {
        return {
            props: { multiple: true, checkStrictly: false, emitPath: false },
            options: areacode,

            modelvalue: '',
            placeholder: '',
            showalllevels: true,
            collapsetags: true,
            collapsetagstooltip: true,

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token

            // 前端维护-过渡字段
            areas: [],

        };
    },
    watch: {
        ModelValue: {
            handler(newVal) {
                this.modelvalue = newVal;
                this.areas = this.modelvalue;
            },
            deep: true,
            immediate: true,
        },
        Placeholder: {
            handler(newVal) {
                this.placeholder = newVal;
            },
            deep: true,
            immediate: true,
        },
        ShowAllLevels: {
            handler(newVal) {
                this.showalllevels = newVal;
            },
            deep: true,
            immediate: true,
        },
        CollapseTags: {
            handler(newVal) {
                this.collapsetags = newVal;
            },
            deep: true,
            immediate: true,
        },
        CollapseTagsTooltip: {
            handler(newVal) {
                this.collapsetagstooltip = newVal;
            },
            deep: true,
            immediate: true,
        },
        CheckStrictly: {
            handler(newVal) {
                this.checkstrictly = newVal;
            },
            deep: true,
            immediate: true,
        },
        EmitPath: {
            handler(newVal) {
                this.emitpath = newVal;
            },
            deep: true,
            immediate: true,
        },

    },
    methods: {
        // 选中
        changeEvent() {
            // 方便后端-当全选时-上传上级code值
            let getCheckedNodes = this.$refs['cascaderRef'].getCheckedNodes();
            let enddata = this.optimizeData(getCheckedNodes).join(',').split(',');
            let setdatas = enddata.filter((item,index)=>{return enddata.indexOf(item) === index});
            this.areas = setdatas;
            this.$emit('changedatas', setdatas)
        },

        optimizeData(data) {
            return data.map(n => { return n.pathValues[n.level - 1] }).filter(n => n);
        },

        // 选中图标
        clickIconEvent(data) {
            this.modelvalue = '';
            if (data == true) {
                this.collapsetags = false
            } else {
                this.collapsetags = true
            }
            setTimeout(() => {
                this.modelvalue = this.areas;
            }, 20);
        },

        // 清空
        clearEvent() {
            this.modelvalue = '';
        }
    },
    created() {
    },
    components: {

    },
};
</script>
  
<style scoped>
.my-cascader-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.my-cascader {
    width: 100%;

}

.my-cascader-icon {
    width: 30px;
    flex-grow: 1;
    font-size: 20px;
    margin-left: 10px;
    color: #999;
}

.my-cascader-icon:hover {
    cursor: pointer;
    color: var(--el-color-primary);
}
</style>