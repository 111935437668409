import {
    createRouter,
    createWebHistory
} from "vue-router";
const routes = [
    //主页面路由,需加入id字段,与后端返的json数据对应 --------------------------------------------
    {
        id: 3,
        path: '/userlist',
        name: 'userlist',
        component: () =>
            import ('@/views/user/user/userlist.vue')
    },
    {
        id: 5,
        path: '/memberplusbuy',
        name: 'memberplusbuy',
        component: () =>
            import ('@/views/user/member/memberplusbuy.vue')
    },
    {
        id: 6,
        path: '/memberpluscash',
        name: 'memberpluscash',
        component: () =>
            import ('@/views/user/member/memberpluscash.vue')
    },
    {
        id: 7,
        path: '/memberdvcbuy',
        name: 'memberdvcbuy',
        component: () =>
            import ('@/views/user/member/memberdvcbuy.vue')
    },
    {
        id: 425,
        path: '/memberinvitestate',
        name: 'memberinvitestate',
        component: () =>
            import ('@/views/user/member/memberinvitestate.vue')
    },
    {
        id: 426,
        path: '/memberinviterecord',
        name: 'memberinviterecord',
        component: () =>
            import ('@/views/user/member/memberinviterecord.vue')
    },
    {
        id: 427,
        path: '/memberinvitegift',
        name: 'memberinvitegift',
        component: () =>
            import ('@/views/user/member/memberinvitegift.vue')
    },
    {
        id: 9,
        path: '/yingbinew',
        name: 'yingbinew',
        component: () =>
            import ('@/views/user/yingbi/yingbinew.vue')
    },
    {
        id: 10,
        path: '/yingbiconsume',
        name: 'yingbiconsume',
        component: () =>
            import ('@/views/user/yingbi/yingbiconsume.vue')
    },
    {
        id: 528,
        path: '/exportlist',
        name: 'exportlist',
        component: () =>
            import ('@/views/user/exportlist/exportlist.vue')
    },
    {
        id: 15,
        path: '/agentlist',
        name: 'agentlist',
        component: () =>
            import ('@/views/collaborate/agent/agentlist/agentlist.vue')
    },
    {
        id: 746,
        path: '/introducer',
        name: 'introducer',
        component: () =>
            import ('@/views/collaborate/agent/introducer/introducer.vue')
    },
    {
        id: 16,
        path: '/dvclist',
        name: 'dvclist',
        component: () =>
            import ('@/views/collaborate/agent/dvclist.vue')
    },
    {
        id: 17,
        path: '/intention',
        name: 'intention',
        component: () =>
            import ('@/views/collaborate/agent/intention.vue')
    },
    {
        // 合作伙伴-代理商管理-报单列表
        id: 728,
        path: '/reportsingle',
        name: 'reportsingle',
        component: () =>
            import ('@/views/collaborate/agent/reportsingle/reportsingle.vue')
    },
    {
        // 合作伙伴-代理商管理-报单列表-详情
        // id: 17,
        path: '/reportsingleinfo',
        name: 'reportsingleinfo',
        component: () =>
            import ('@/views/collaborate/agent/reportsingle/reportsingleinfo.vue')
    },

    {
        id: 19,
        path: '/partnerlist',
        name: 'partnerlist',
        component: () =>
            import ('@/views/collaborate/partner/partnerlist.vue')
    },
    {
        id: 20,
        path: '/contractlist',
        name: 'contractlist',
        component: () =>
            import ('@/views/collaborate/partner/contractlist/contractlist.vue')
    },
    {
        id: 21,
        path: '/dvcflowlist',
        name: 'dvcflowlist',
        component: () =>
            import ('@/views/collaborate/partner/dvcflowlist/dvcflowlist.vue')
    },
    {
        id: 22,
        path: '/partnercash',
        name: 'partnercash',
        component: () =>
            import ('@/views/collaborate/partner/partnercash.vue')
    },
    {
        id: 25,
        path: '/sharedvccontrol',
        name: 'sharedvccontrol',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontrol.vue')
    },
    {
        id: 26,
        path: '/sharedvcmap',
        name: 'sharedvcmap',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcmap.vue')
    },
    {
        id: 441,
        path: '/sharedvcmap2',
        name: 'sharedvcmap2',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcmap2.vue')
    },
    {
        id: 681,
        path: '/sharedvcmapfd',
        name: 'sharedvcmapfd',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcmapfd.vue')
    },
    {
        id: 595,
        path: '/yeardvccountsum',
        name: 'yeardvccountsum',
        component: () =>
            import ('@/views/census/print/yeardvccountsum/yeardvccountsum.vue')
    },
    {
        id: 718,
        path: '/yeardvccount',
        name: 'yeardvccount',
        component: () =>
            import ('@/views/census/print/yeardvccount/yeardvccount.vue')
    },
    {
        // 统计-企微统计-代理商统计
        id: 736,
        path: '/agentplusfans',
        name: 'agentplusfans',
        component: () =>
            import ('@/views/census/wecom/agentplusfans/agentplusfans.vue')
    },
    {
        // 统计-企微统计-设备统计
        id: 737,
        path: '/dvcfanscount',
        name: 'dvcfanscount',
        component: () =>
            import ('@/views/census/wecom/dvcfanscount/dvcfanscount.vue')
    },
    {
        // 统计-业务统计-业务人员统计
        id: 741,
        path: '/serviceusercens',
        name: 'serviceusercens',
        component: () =>
            import ('@/views/census/service/serviceusercens/serviceusercens.vue')
    },
    {
        // 统计-业务统计-报单统计
        id: 742,
        path: '/reportsinglecens',
        name: 'reportsinglecens',
        component: () =>
            import ('@/views/census/service/reportsinglecens/reportsinglecens.vue')
    },
    {
        // 统计-业务统计-报单统计-报单详情
        // id: 742,
        path: '/reportsinglecensinfo',
        name: 'reportsinglecensinfo',
        component: () =>
            import ('@/views/census/service/reportsinglecens/reportsinglecensinfo.vue')
    },
    {
        id: 27,
        path: '/sharedvcexfactory',
        name: 'sharedvcexfactory',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcexfactory.vue')
    },
    {
        id: 28,
        path: '/sharedvcback',
        name: 'sharedvcback',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcback.vue')
    },
    {
        id: 29,
        path: '/sharedvcinstall',
        name: 'sharedvcinstall',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcinstall.vue')
    },
    {
        id: 31,
        path: '/photoid',
        name: 'photoid',
        component: () =>
            import ('@/views/print/photo/photoid/photoid.vue')
    },
    {
        id: 33,
        path: '/resourcesglobal',
        name: 'resourcesglobal',
        component: () =>
            import ('@/views/print/resources/resourcesglobal/resourcesglobal.vue')
    },
    {
        id: 34,
        path: '/resourcesclassify',
        name: 'resourcesclassify',
        component: () =>
            import ('@/views/print/resources/resourcesclassify/resourcesclassify.vue')
    },
    {
        id: 37,
        path: '/printresources',
        name: 'printresources',
        component: () =>
            import ('@/views/education/files/printresources/printresources.vue')
    },
    {
        id: 38,
        path: '/fileclassify',
        name: 'fileclassify',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassify.vue')
    },
    {
        id: 39,
        path: '/labelmanage',
        name: 'labelmanage',
        component: () =>
            import ('@/views/education/files/labelmanage/labelmanage.vue')
    },
    {
        id: 40,
        path: '/recommendposition',
        name: 'recommendposition',
        component: () =>
            import ('@/views/education/files/recommendposition.vue')
    },
    {
        id: 42,
        path: '/curriculumlist',
        name: 'curriculumlist',
        component: () =>
            import ('@/views/education/curriculum/curriculumlist/curriculumlist.vue')
    },
    {
        id: 43,
        path: '/curriculumclassify',
        name: 'curriculumclassify',
        component: () =>
            import ('@/views/education/curriculum/curriculumclassify/curriculumclassify.vue')
    },
    {
        id: 44,
        path: '/coursewarelist',
        name: 'coursewarelist',
        component: () =>
            import ('@/views/education/curriculum/coursewarelist/coursewarelist.vue')
    },
    {
        id: 45,
        path: '/coursewareclassify',
        name: 'coursewareclassify',
        component: () =>
            import ('@/views/education/curriculum/coursewareclassify/coursewareclassify.vue')
    },
    {
        id: 48,
        path: '/orderlist',
        name: 'orderlist',
        component: () =>
            import ('@/views/order/print/orderlist/orderlist.vue')
    },
    {
        id: 601,
        path: '/orderbelong',
        name: 'orderbelong',
        component: () =>
            import ('@/views/order/print/orderbelong/orderbelong.vue')
    },
    {
        id: 49,
        path: '/refundapplication',
        name: 'refundapplication',
        component: () =>
            import ('@/views/order/print/refundapplication/refundapplication.vue')
    },
    {
        id: 51,
        path: '/curriculumorder',
        name: 'curriculumorder',
        component: () =>
            import ('@/views/order/education/curriculumorder.vue')
    },
    {
        id: 52,
        path: '/libraryorderlist',
        name: 'libraryorderlist',
        component: () =>
            import ('@/views/order/education/libraryorder.vue')
    },
    {
        id: 54,
        path: '/advertiserslist',
        name: 'advertiserslist',
        component: () =>
            import ('@/views/order/advertisers/advertiserslist/advertiserslist.vue')
    },
    {
        id: 55,
        path: '/advertisersspread',
        name: 'advertisersspread',
        component: () =>
            import ('@/views/order/advertisers/advertisersspread/advertisersspread.vue')
    },
    {
        id: 56,
        path: '/printwriteoff',
        name: 'printwriteoff',
        component: () =>
            import ('@/views/order/advertisers/printwriteoff.vue')
    },
    {
        id: 59,
        path: '/appletadvert',
        name: 'appletadvert',
        component: () =>
            import ('@/views/operate/applet/appletadvert.vue')
    },
    {
        id: 61,
        path: '/advertlist',
        name: 'advertlist',
        component: () =>
            import ('@/views/operate/advertdvc/advertlist/advertlist.vue')
    },
    {
        id: 62,
        path: '/advertexamine',
        name: 'advertexamine',
        component: () =>
            import ('@/views/operate/advertdvc/advertexamine.vue')
    },
    {
        id: 63,
        path: '/advertappeal',
        name: 'advertappeal',
        component: () =>
            import ('@/views/operate/advertdvc/advertappeal/advertappeal.vue')
    },
    {
        id: 65,
        path: '/coupon',
        name: 'coupon',
        component: () =>
            import ('@/views/operate/play/coupon/coupon.vue')
    },
    {
        path: '/couponadd',
        name: 'couponadd',
        component: () =>
            import ('@/views/operate/play/coupon/couponadd.vue')
    },
    {
        path: '/couponinfo',
        name: 'couponinfo',
        component: () =>
            import ('@/views/operate/play/coupon/couponinfo.vue')
    },
    {
        path: '/coupondata',
        name: 'coupondata',
        component: () =>
            import ('@/views/operate/play/coupon/coupondata.vue')
    },
    {
        path: '/couponedit',
        name: 'couponedit',
        component: () =>
            import ('@/views/operate/play/coupon/couponedit.vue')
    },
    {
        id: 66,
        path: '/luckydraw',
        name: 'luckydraw',
        component: () =>
            import ('@/views/operate/play/luckydraw/luckydraw.vue')
    },
    {
        id: 67,
        path: '/phonecard',
        name: 'phonecard',
        component: () =>
            import ('@/views/operate/play/phonecard.vue')
    },
    {
        id: 544,
        path: '/ordergoods',
        name: 'ordergoods',
        component: () =>
            import ('@/views/operate/play/ordergoods.vue')
    },
    {
        id: 572,
        path: '/formclue',
        name: 'formclue',
        component: () =>
            import ('@/views/operate/play/formclue/formclue.vue')
    },
    {
        id: 690,
        path: '/publicnumset',
        name: 'publicnumset',
        component: () =>
            import ('@/views/operate/play/publicnumset/publicnumset.vue')
    },
    {
        id: 692,
        path: '/publicnumcapital',
        name: 'publicnumcapital',
        component: () =>
            import ('@/views/finance/capital/publicnumcapital/publicnumcapital.vue')
    },
    {
        id: 636,
        path: '/paymoneypostgoods',
        name: 'paymoneypostgoods',
        component: () =>
            import ('@/views/operate/play/paymoneypostgoods/paymoneypostgoods.vue')
    },
    {
        id: 68,
        path: '/memberactive',
        name: 'memberactive',
        component: () =>
            import ('@/views/operate/play/memberactive.vue')
    },
    {
        id: 69,
        path: '/bossintcus',
        name: 'bossintcus',
        component: () =>
            import ('@/views/operate/play/bossintcus.vue')
    },
    {
        id: 71,
        path: '/commonproblem',
        name: 'commonproblem',
        component: () =>
            import ('@/views/operate/play/commonproblem/commonproblem.vue')
    },

    // 企业微信
    {
        // 链接标题
        id: 703,
        path: '/linktitle',
        name: 'linktitle',
        component: () =>
            import ('@/views/operate/wecom/linktitle/linktitle.vue')
    },
    {
        // 链接标题-添加
        path: '/linktitleadd',
        name: 'linktitleadd',
        component: () =>
            import ('@/views/operate/wecom/linktitle/linktitleadd.vue')
    },
    {
        // 链接标题-编辑
        path: '/linktitleedit',
        name: 'linktitleedit',
        component: () =>
            import ('@/views/operate/wecom/linktitle/linktitleedit.vue')
    },
    {
        // 小程序加企业微信
        id: 712,
        path: '/minipluswecom',
        name: 'minipluswecom',
        component: () =>
            import ('@/views/operate/wecom/minipluswecom/minipluswecom.vue')
    },
    {
        // 小程序加企业微信-添加
        path: '/minipluswecomadd',
        name: 'minipluswecomadd',
        component: () =>
            import ('@/views/operate/wecom/minipluswecom/minipluswecomadd.vue')
    },
    {
        // 小程序加企业微信-编辑
        path: '/minipluswecomedit',
        name: 'minipluswecomedit',
        component: () =>
            import ('@/views/operate/wecom/minipluswecom/minipluswecomedit.vue')
    },
    {
        // 商户加企业微信
        id: 708,
        path: '/shoppluswecom',
        name: 'shoppluswecom',
        component: () =>
            import ('@/views/operate/wecom/shoppluswecom/shoppluswecom.vue')
    },
    {
        // 商户加企业微信-添加
        path: '/shoppluswecomadd',
        name: 'shoppluswecomadd',
        component: () =>
            import ('@/views/operate/wecom/shoppluswecom/shoppluswecomadd.vue')
    },
    {
        // 商户加企业微信-编辑
        path: '/shoppluswecomedit',
        name: 'shoppluswecomedit',
        component: () =>
            import ('@/views/operate/wecom/shoppluswecom/shoppluswecomedit.vue')
    },

    {
        id: 74,
        path: '/account',
        name: 'account',
        component: () =>
            import ('@/views/finance/capital/account.vue')
    },
    {
        id: 75,
        path: '/recagent',
        name: 'recagent',
        component: () =>
            import ('@/views/finance/capital/recagent.vue')
    },
    {
        id: 76,
        path: '/entrynote',
        name: 'entrynote',
        component: () =>
            import ('@/views/finance/capital/entrynote.vue')
    },
    {
        id: 77,
        path: '/postingdetails',
        name: 'postingdetails',
        component: () =>
            import ('@/views/finance/capital/postingdetails.vue')
    },
    {
        id: 78,
        path: '/statementuser',
        name: 'statementuser',
        component: () =>
            import ('@/views/finance/capital/statementuser.vue')
    },
    {
        id: 79,
        path: '/statementagent',
        name: 'statementagent',
        component: () =>
            import ('@/views/finance/capital/statementagent.vue')
    },
    {
        id: 80,
        path: '/statementpartner',
        name: 'statementpartner',
        component: () =>
            import ('@/views/finance/capital/statementpartner.vue')
    },
    {
        id: 81,
        path: '/balancetransfer',
        name: 'balancetransfer',
        component: () =>
            import ('@/views/finance/capital/balancetransfer.vue')
    },
    {
        id: 82,
        path: '/balanceredpacket',
        name: 'balanceredpacket',
        component: () =>
            import ('@/views/finance/capital/balanceredpacket/balanceredpacket.vue')
    },
    {
        id: 83,
        path: '/balanceredpacketdvc',
        name: 'balanceredpacketdvc',
        component: () =>
            import ('@/views/finance/capital/balanceredpacketdvc/balanceredpacketdvc.vue')
    },
    {
        id: 570,
        path: '/statementplatform',
        name: 'statementplatform',
        component: () =>
            import ('@/views/finance/capital/statementplatform/statementplatform.vue')
    },
    {
        id: 417,
        path: '/jiaoyimingxi',
        name: 'jiaoyimingxi',
        component: () =>
            import ('@/views/finance/capital/jiaoyimingxi/jiaoyimingxi.vue')
    },
    {
        id: 85,
        path: '/bonddetailed',
        name: 'bonddetailed',
        component: () =>
            import ('@/views/finance/bond/bonddetailed.vue')
    },
    {
        id: 86,
        path: '/bondapplyback',
        name: 'bondapplyback',
        component: () =>
            import ('@/views/finance/bond/bondapplyback/bondapplyback.vue')
    },
    {
        id: 88,
        path: '/withdrawalrecord',
        name: 'withdrawalrecord',
        component: () =>
            import ('@/views/finance/withdrawal/withdrawalrecord.vue')
    },
    {
        id: 89,
        path: '/withdrawalremind',
        name: 'withdrawalremind',
        component: () =>
            import ('@/views/finance/withdrawal/withdrawalremind.vue')
    },
    {
        id: 553,
        path: '/platformprintsubcom',
        name: 'platformprintsubcom',
        component: () =>
            import ('@/views/finance/withdrawal/platformprintsubcom.vue')
    },
    {
        id: 390,
        path: '/workorderlist',
        name: 'workorderlist',
        component: () =>
            import ('@/views/aftersale/workorderinfo/workorderlist.vue')
    },
    {
        id: 92,
        path: '/feedbackopinion',
        name: 'feedbackopinion',
        component: () =>
            import ('@/views/aftersale/feedbackinfo/feedbackopinion.vue')
    },
    {
        id: 95,
        path: '/printoverview',
        name: 'printoverview',
        component: () =>
            import ('@/views/census/print/printoverview.vue')
    },
    {
        id: 96,
        path: '/agentcensus',
        name: 'agentcensus',
        component: () =>
            import ('@/views/census/print/agentcensus/agentcensus.vue')
    },
    {
        id: 394,
        path: '/agentdivide',
        name: 'agentdivide',
        component: () =>
            import ('@/views/census/print/agentdivide/agentdivide.vue')
    },
    {
        id: 97,
        path: '/dvcordercensus',
        name: 'dvcordercensus',
        component: () =>
            import ('@/views/census/print/dvcordercensus/dvcordercensus.vue')
    },
    {
        id: 98,
        path: '/dvcorderranking',
        name: 'dvcorderranking',
        component: () =>
            import ('@/views/census/print/dvcorderranking.vue')
    },
    {
        id: 574,
        path: '/systemoutprint',
        name: 'systemoutprint',
        component: () =>
            import ('@/views/census/print/systemoutprint/systemoutprint.vue')
    },
    {
        id: 99,
        path: '/saleflow',
        name: 'saleflow',
        component: () =>
            import ('@/views/census/print/saleflow.vue')
    },
    {
        id: 100,
        path: '/aftersaleflow',
        name: 'aftersaleflow',
        component: () =>
            import ('@/views/census/print/aftersaleflow.vue')
    },
    {
        id: 396,
        path: '/invitemember',
        name: 'invitemember',
        component: () =>
            import ('@/views/census/member/invitemember.vue')
    },
    {
        id: 397,
        path: '/dvcprint',
        name: 'dvcprint',
        component: () =>
            import ('@/views/census/member/dvcprint.vue')
    },
    {
        id: 102,
        path: '/libraryorder',
        name: 'libraryorder',
        component: () =>
            import ('@/views/census/education/libraryorder.vue')
    },
    {
        id: 105,
        path: '/system',
        name: 'system',
        component: () =>
            import ('@/views/set/systemset/system.vue')
    },
    {
        id: 112,
        path: '/setstyle',
        name: 'setstyle',
        component: () =>
            import ('@/views/set/diy/style.vue')
    },
    {
        id: 113,
        path: '/sethome',
        name: 'sethome',
        component: () =>
            import ('@/views/set/diy/home.vue')
    },

    {
        id: 114,
        path: '/setfooter',
        name: 'setfooter',
        component: () =>
            import ('@/views/set/diy/footer.vue')
    },
    {
        id: 567,
        path: '/weipages',
        name: '/weipages',
        component: () =>
            import ('@/views/set/diy/weipages/weipages.vue')
    },
    {
        id: 110,
        path: '/dvcplusset',
        name: 'dvcplusset',
        component: () =>
            import ('@/views/set/memberset/dvcplusset.vue')
    },
    {
        id: 108,
        path: '/plusset',
        name: 'plusset',
        component: () =>
            import ('@/views/set/memberset/plusset.vue')
    },
    {
        id: 421,
        path: '/plusinvite',
        name: 'plusinvite',
        component: () =>
            import ('@/views/set/memberset/plusinvite.vue')
    },
    {
        id: 106,
        path: '/accountset',
        name: 'accountset',
        component: () =>
            import ('@/views/set/systemset/accountset.vue')
    },
    {
        id: 109,
        path: '/plussetdisable',
        name: 'plussetdisable',
        component: () =>
            import ('@/views/set/memberset/plussetdisable.vue')
    },
    {
        id: 117,
        path: '/department',
        name: 'department',
        component: () =>
            import ('@/views/power/power/department/department.vue')
    },
    {
        id: 118,
        path: '/power',
        name: 'power',
        component: () =>
            import ('@/views/power/power/power/power.vue')
    },
    {
        id: 119,
        path: '/user',
        name: 'user',
        component: () =>
            import ('@/views/power/power/user/user.vue')
    },
    {
        id: 695,
        path: '/serviceuser',
        name: 'serviceuser',
        component: () =>
            import ('@/views/power/power/service/serviceuser.vue')
    },
    {
        path: '/serviceuseradd',
        name: 'serviceuseradd',
        component: () =>
            import ('@/views/power/power/service/serviceuseradd.vue')
    },
    {
        path: '/serviceuseredit',
        name: 'serviceuseredit',
        component: () =>
            import ('@/views/power/power/service/serviceuseredit.vue')
    },
    {
        // 权限-权限管理-运营人员管理
        id: 732,
        path: '/operatuser',
        name: 'operatuser',
        component: () =>
            import ('@/views/power/power/operatuser/operatuser.vue')
    },
    {
        id: 120,
        path: '/saleuser',
        name: 'saleuser',
        component: () =>
            import ('@/views/power/power/saleuser/saleuser.vue')
    },
    {
        id: 123,
        path: '/loginlog',
        name: 'loginlog',
        component: () =>
            import ('@/views/power/menu/loginlog.vue')
    },
    {
        id: 566,
        path: '/personalinfo',
        name: 'personalinfo',
        component: () =>
            import ('@/views/set/diy/personalinfo/personalinfo.vue')
    },
    {
        // 店铺-DIY-首页布局
        id: 564,
        path: '/minilayout',
        name: 'minilayout',
        component: () =>
            import ('@/views/set/diy/minilayout/minilayout.vue')
    },
    {
        // 统计-告警统计-离线告警统计
        id: 577,
        path: '/offlinestatistics',
        name: 'offlinestatistics',
        component: () =>
            import ('@/views/census/offlinestatistics/offlinestatistics.vue')
    },
    {
        // 统计-打印统计-设备使用情况
        id: 626,
        path: '/dvcuseinfo',
        name: 'dvcuseinfo',
        component: () =>
            import ('@/views/census/print/dvcuseinfo/dvcuseinfo.vue')
    },


    //以下是配置页面&子页面等页面路由 --------------------------------------------
    {
        //登录
        path: '/',
        name: 'login',
        component: () =>
            import ('@/views/login/login.vue')
    },
    {
        //404
        path: '/404',
        name: '404',
        component: () =>
            import ('@/components/404.vue')
    },
    {
        //用户-用户管理-用户列表-用户信息
        path: '/userinfolist',
        name: 'userinfolist',
        component: () =>
            import ('@/views/user/user/userinfolist.vue')
    },
    { //合作-代理商管理-代理商列表-代理商详情
        path: '/agentlistinfo',
        name: 'agentlistinfo',
        component: () =>
            import ('@/views/collaborate/agent/agentlist/agentlistinfo.vue')
    },
    { //合作-代理商管理-代理商列表-添加代理商
        path: '/agentlistadd',
        name: 'agentlistadd',
        component: () =>
            import ('@/views/collaborate/agent/agentlist/agentlistadd.vue')
    },
    { //合作-代理商管理-代理商列表-修改代理商
        path: '/agentlistedit',
        name: 'agentlistedit',
        component: () =>
            import ('@/views/collaborate/agent/agentlist/agentlistedit.vue')
    },
    { //合作-设备合伙人-设备合伙人列表-设备合伙人设备列表
        path: '/partnerdvclist',
        name: 'partnerdvclist',
        component: () =>
            import ('@/views/collaborate/partner/partnerdvclist.vue')
    },
    { //合作-设备合伙人-设备合伙人列表-设备合伙人设备列表
        path: '/dvcflowadd',
        name: 'dvcflowadd',
        component: () =>
            import ('@/views/collaborate/partner/dvcflowlist/dvcflowadd.vue')
    },
    { //合作-设备合伙人-合同列表-添加合同
        path: '/contractlistadd',
        name: 'contractlistadd',
        component: () =>
            import ('@/views/collaborate/partner/contractlist/contractlistadd.vue')
    },
    { //打印-共享打印-机器控制-编辑设备-远程控制
        path: '/sharedvccontrolfrp',
        name: 'sharedvccontrolfrp',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontrolfrp.vue')
    },
    { //打印-共享打印-机器控制-查看日志
        path: '/sharedvccontrolseelog',
        name: 'sharedvccontrolseelog',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontrolseelog.vue')
    },
    { //打印-共享打印-机器控制-在线时长
        path: '/dvcisonline',
        name: 'dvcisonline',
        component: () =>
            import ('@/views/print/sharedvc/dvcisonline.vue')
    },
    { //打印-资源管理-资源分类-添加分类
        path: '/resourcesclassifyadd',
        name: 'resourcesclassifyadd',
        component: () =>
            import ('@/views/print/resources/resourcesclassify/resourcesclassifyadd.vue')
    },
    { //打印-证件照打印-证件照-添加尺寸
        path: '/photoidedit',
        name: 'photoidedit',
        component: () =>
            import ('@/views/print/photo/photoid/photoidedit.vue')
    },
    { //打印-证件照打印-证件照-添加尺寸
        path: '/photoidadd',
        name: 'photoidadd',
        component: () =>
            import ('@/views/print/photo/photoid/photoidadd.vue')
    },
    { //打印-资源管理-全球拍资源-添加图片
        path: '/resourcesglobaladd',
        name: 'resourcesglobaladd',
        component: () =>
            import ('@/views/print/resources/resourcesglobal/resourcesglobaladd.vue')
    },
    { //教育-文档管理-打印资源库-添加文档
        path: '/printresourcesadd',
        name: 'printresourcesadd',
        component: () =>
            import ('@/views/education/files/printresources/printresourcesadd.vue')
    },
    { //教育-文档管理-打印资源库-批量添加文档
        path: '/printresourcesaddmore',
        name: 'printresourcesaddmore',
        component: () =>
            import ('@/views/education/files/printresources/printresourcesaddmore.vue')
    },
    { //教育-文档管理-打印资源库-审核文档
        path: '/resourcesexamine',
        name: 'resourcesexamine',
        component: () =>
            import ('@/views/education/files/printresources/resourcesexamine.vue')
    },
    { //教育-文档管理-打印资源库-编辑文档
        path: '/resourcesedit',
        name: 'resourcesedit',
        component: () =>
            import ('@/views/education/files/printresources/resourcesedit.vue')
    },
    { //教育-文档管理-文档分类-添加分类
        path: '/fileclassifyadd',
        name: 'fileclassifyadd',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassifyadd.vue')
    },
    { //教育-文档管理-标签管理-编辑标签
        path: '/labelmanageadd',
        name: 'labelmanageadd',
        component: () =>
            import ('@/views/education/files/labelmanage/labelmanageadd.vue')
    },
    { //教育-课程管理-课程列表-添加课程
        path: '/curriculumlistadd',
        name: 'curriculumlistadd',
        component: () =>
            import ('@/views/education/curriculum/curriculumlist/curriculumlistadd.vue')
    },
    { //教育-课程管理-课程分类-添加分类
        path: '/curriculumclassifyadd',
        name: 'curriculumclassifyadd',
        component: () =>
            import ('@/views/education/curriculum/curriculumclassify/curriculumclassifyadd.vue')
    },
    {
        //教育-课程管理-课件列表-添加课件
        path: '/coursewarelistadd',
        name: 'coursewarelistadd',
        component: () =>
            import ('@/views/education/curriculum/coursewarelist/coursewarelistadd.vue')
    },
    { //教育-课程管理-课件分类-添加分类
        path: '/coursewareclassifyadd',
        name: 'coursewareclassifyadd',
        component: () =>
            import ('@/views/education/curriculum/coursewareclassify/coursewareclassifyadd.vue')
    },
    {
        //订单-打印管理-订单列表-订单详情
        path: '/orderlistinfo',
        name: 'orderlistinfo',
        component: () =>
            import ('@/views/order/print/orderlist/orderlistinfo.vue')
    },
    {
        //订单-打印管理-退款申请-查看(退款)详情
        path: '/refundapplicationinfo',
        name: 'refundapplicationinfo',
        component: () =>
            import ('@/views/order/print/refundapplication/refundapplicationinfo.vue')
    },
    {
        //订单-打印管理-退款申请(商户版)-查看(退款)详情
        path: '/refundapplicationinfomerge',
        name: 'refundapplicationinfomerge',
        component: () =>
            import ('@/views/order/print/refundapplication/refundapplicationinfomerge.vue')
    },
    {
        //订单-打印管理-退款申请-查看(退款)详情-查看微信投诉详情
        path: '/refundwechatinfo',
        name: 'refundwechatinfo',
        component: () =>
            import ('@/views/order/print/refundapplication/refundwechatinfo.vue')
    },
    {
        //订单-打印管理-退款申请-查看(退款)详情-查看微信投诉详情
        path: '/refundwechatinfomerge',
        name: 'refundwechatinfomerge',
        component: () =>
            import ('@/views/order/print/refundapplication/refundwechatinfomerge.vue')
    },
    {
        //订单-广告主管理-广告主列表-广告主详情
        path: '/advertiserslistinfo',
        name: 'advertiserslistinfo',
        component: () =>
            import ('@/views/order/advertisers/advertiserslist/advertiserslistinfo.vue')
    },
    {
        //订单-广告主管理-广告推广方案-推广方案详情
        path: '/advertisersspreadinfo',
        name: 'advertisersspreadinfo',
        component: () =>
            import ('@/views/order/advertisers/advertisersspread/advertisersspreadinfo.vue')
    },
    {
        //售后-工单列表-工单详情
        path: '/workorderinfo',
        name: 'workorderinfo',
        component: () =>
            import ('@/views/aftersale/workorderinfo/workorderinfo.vue')
    },
    { //运营-小程序广告位管理-广告位列表-添加广告位
        path: '/appletadvertadd',
        name: 'appletadvertadd',
        component: () =>
            import ('@/views/operate/applet/appletadvertadd.vue')
    },
    { //运营-小程序广告位管理-广告位列表-编辑广告位
        path: '/appletadvertedit',
        name: 'appletadvertedit',
        component: () =>
            import ('@/views/operate/applet/appletadvertedit.vue')
    },
    { //运营-设备广告管理-广告列表-添加设备广告
        path: '/advertlistadd',
        name: 'advertlistadd',
        component: () =>
            import ('@/views/operate/advertdvc/advertlist/advertlistadd.vue')
    },
    {
        //运营-设备广告管理-广告列表-设备广告信息
        path: '/advertlistinfo',
        name: 'advertlistinfo',
        component: () =>
            import ('@/views/operate/advertdvc/advertlist/advertlistinfo.vue')
    },
    {
        //运营-设备广告管理-广告列表-设备广告信息-查看设备详情
        path: '/lookdevice',
        name: 'lookdevice',
        component: () =>
            import ('@/views/operate/advertdvc/advertlist/lookdevice.vue')
    },
    {
        //运营-设备广告管理-广告列表-设备广告信息-查看设备详情-播放详情
        path: '/playinfo',
        name: 'playinfo',
        component: () =>
            import ('@/views/operate/advertdvc/advertlist/playinfo.vue')
    },
    {
        //运营-设备广告管理-广告申诉-申诉详情
        path: '/advertappealinfo',
        name: 'advertappealinfo',
        component: () =>
            import ('@/views/operate/advertdvc/advertappeal/advertappealinfo.vue')
    },
    {
        //运营-营销手法-幸运大抽奖-抽奖记录
        path: '/luckydrawrecord',
        name: 'luckydrawrecord',
        component: () =>
            import ('@/views/operate/play/luckydraw/luckydrawrecord.vue')
    },
    {
        ///运营-营销手法-幸运大抽奖-增加抽奖
        path: '/luckydrawrecordadd',
        name: 'luckydrawrecordadd',
        component: () =>
            import ('@/views/operate/play/luckydraw/luckydrawrecordadd.vue')
    },
    {
        ///运营-营销手法-幸运大抽奖-编辑抽奖
        path: '/luckydrawrecordedit',
        name: 'luckydrawrecordedit',
        component: () =>
            import ('@/views/operate/play/luckydraw/luckydrawrecordedit.vue')
    },
    {
        ///运营-营销手法-幸运大抽奖-查看详情
        path: '/luckydrawinfo',
        name: 'luckydrawinfo',
        component: () =>
            import ('@/views/operate/play/luckydraw/luckydrawinfo.vue')
    },
    {
        //财务-资金对账-余额红包券-红包领取明细
        path: '/redpacketdetailed',
        name: 'redpacketdetailed',
        component: () =>
            import ('@/views/finance/capital/balanceredpacket/redpacketdetailed.vue')
    },
    {
        //财务-资金对账-设备余额红包券-设备红包码
        path: '/dvcredpacketcode',
        name: 'dvcredpacketcode',
        component: () =>
            import ('@/views/finance/capital/balanceredpacketdvc/dvcredpacketcode.vue')
    },
    {
        //财务-资金对账-设备余额红包券-设备红包领取明细
        path: '/dvcredpacketdetailed',
        name: 'dvcredpacketdetailed',
        component: () =>
            import ('@/views/finance/capital/balanceredpacketdvc/dvcredpacketdetailed.vue')
    },
    {
        //财务-保证金-申请退保证金-申请退保证金详情
        path: '/bondapplybackinfo',
        name: 'bondapplybackinfo',
        component: () =>
            import ('@/views/finance/bond/bondapplyback/bondapplybackinfo.vue')
    },
    {
        //统计-打印统计-代理商分成预警-代理商分成预警明细
        path: '/agentdividedetailed',
        name: 'agentdividedetailed',
        component: () =>
            import ('@/views/census/print/agentdivide/agentdividedetailed.vue')
    },
    {
        //统计-打印统计-设备订单统计-设备订单明细
        path: '/dvcorderdetailed',
        name: 'dvcorderdetailed',
        component: () =>
            import ('@/views/census/print/dvcordercensus/dvcordercensusdetail.vue')
    },
    {
        //售后-意见反馈-反馈详情
        path: '/feedbackopinioninfo',
        name: 'feedbackopinioninfo',
        component: () =>
            import ('@/views/aftersale/feedbackinfo/feedbackopinioninfo.vue')
    },
    { //打印-资源管理-全球拍资源-编辑图片
        path: '/resourcesglobaledit',
        name: 'resourcesglobaledit',
        component: () =>
            import ('@/views/print/resources/resourcesglobal/resourcesglobaledit.vue')
    },
    { //打印-资源管理-图片分类-编辑分类
        path: '/resourcesclassifyedit',
        name: 'resourcesclassifyedit',
        component: () =>
            import ('@/views/print/resources/resourcesclassify/resourcesclassifyedit.vue')
    },
    { //权限-权限管理-用户管理-添加员工
        path: '/useradd',
        name: 'useradd',
        component: () =>
            import ('@/views/power/power/user/useradd.vue')
    },
    { //权限-权限管理-用户管理-编辑员工
        path: '/useredit',
        name: 'useredit',
        component: () =>
            import ('@/views/power/power/user/useredit.vue')
    },
    { //权限-权限管理-销售/售后用户管理-添加员工
        path: '/saleuseradd',
        name: 'saleuseradd',
        component: () =>
            import ('@/views/power/power/saleuser/saleuseradd.vue')
    },
    { //权限-权限管理-销售/售后用户管理-编辑员工
        path: '/saleuseredit',
        name: 'saleuseredit',
        component: () =>
            import ('@/views/power/power/saleuser/saleuseredit.vue')
    },
    { //教育-文档管理-文档分类-编辑分类
        path: '/fileclassifyedit',
        name: 'fileclassifyedit',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassifyedit.vue')
    },
    { //教育-文档管理-文档分类-新增下级
        path: '/fileclassifyaddlast',
        name: 'fileclassifyaddlast',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassifyaddlast.vue')
    },
    { //教育-文档管理-标签管理-编辑标签
        path: '/labelmanageedit',
        name: 'labelmanageedit',
        component: () =>
            import ('@/views/education/files/labelmanage/labelmanageedit')
    },
    { //教育-课程管理-课程分类-编辑分类
        path: '/curriculumclassifyedit',
        name: 'curriculumclassifyedit',
        component: () =>
            import ('@/views/education/curriculum/curriculumclassify/curriculumclassifyedit.vue')
    },
    { //教育-课程管理-课件分类-编辑分类
        path: '/coursewareclassifyedit',
        name: 'coursewareclassifyedit',
        component: () =>
            import ('@/views/education/curriculum/coursewareclassify/coursewareclassifyedit.vue')
    },
    {
        //教育-课程管理-课件列表-编辑课件
        path: '/coursewarelistedit',
        name: 'coursewarelistedit',
        component: () =>
            import ('@/views/education/curriculum/coursewarelist/coursewarelistedit.vue')
    },
    {
        //教育-课程管理-课程列表-编辑课程
        path: '/curriculumlistedit',
        name: 'curriculumlistedit',
        component: () =>
            import ('@/views/education/curriculum/curriculumlist/curriculumlistedit.vue')
    },
    {
        //运营-服务中心-常见问题-添加问题
        path: '/commonproblemadd',
        name: 'commonproblemadd',
        component: () =>
            import ('@/views/operate/play/commonproblem/commonproblemadd.vue')
    },
    {
        //运营-服务中心-常见问题-编辑问题
        path: '/commonproblemedit',
        name: 'commonproblemedit',
        component: () =>
            import ('@/views/operate/play/commonproblem/commonproblemedit.vue')
    },
    {
        //共享打印--机器控制--编辑设备
        path: '/sharedvccontroledit',
        name: 'sharedvccontroledit',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontroledit.vue')
    },
    {
        //设置-DIY-底部导航-添加
        path: '/setfooteradd',
        name: 'setfooteradd',
        component: () =>
            import ('@/views/set/diy/footeradd.vue')
    },
    {
        //店铺-装修-微页面-添加微页面
        path: '/weipagesadd',
        name: 'weipagesadd',
        component: () =>
            import ('@/views/set/diy/weipages/weipagesadd.vue')
    },
    {
        //店铺-装修-微页面-编辑微页面
        path: '/weipagesedit',
        name: 'weipagesedit',
        component: () =>
            import ('@/views/set/diy/weipages/weipagesedit.vue')
    },
    {
        //店铺-装修-微页面-预览微页面
        path: '/vweipagespreview',
        name: 'vweipagespreview',
        component: () =>
            import ('@/vspecial/set/diy/weipages/vweipagespreview.vue')
    },
    {
        //店铺-装修-微页面-H5显示页面(组件)
        path: '/weipagespromocode',
        name: 'weipagespromocode',
        component: () =>
            import ('@/vspecial/set/diy/weipages/weipagespromocode.vue')
    },
    {
        //店铺-装修-微页面-H5页面
        path: '/weipageshtml5',
        name: 'weipageshtml5',
        component: () =>
            import ('@/vspecial/set/diy/weipages/weipageshtml5.vue')
    },
    {
        //店铺-装修-个人中心-菜单工具栏
        path: '/toolsedit',
        name: 'toolsedit',
        component: () =>
            import ('@/views/set/diy/personalinfo/toolsedit.vue')
    },
    {
        //店铺-装修-个人中心-编辑
        path: '/menuedit',
        name: 'menuedit',
        component: () =>
            import ('@/views/set/diy/personalinfo/menuedit.vue')
    },
    {
        //权限-权限管理-用户管理-设置
        path: '/userset',
        name: 'userset',
        component: () =>
            import ('@/views/power/power/user/userset.vue')
    },

    {
        path: '/vpowerset',
        name: 'vpowerset',
        component: () =>
            import ('@/views/power/power/power/powerset.vue')
    },
    {
        id: 504,
        path: '/refundapplicationmerge',
        name: 'refundapplicationmerge',
        component: () =>
            import ('@/views/order/print/refundapplication/refundapplicationmerge.vue')
    },

    // 金融产品相关router
    {
        // 金融产品设置列表
        id: 584,
        path: '/setderivativeslist',
        name: 'setderivativeslist',
        component: () =>
            import ('@/views/derivatives/set/setderivatives/setderivativeslist.vue')
    },
    {
        // 金融产品设置
        path: '/setderivatives',
        name: 'setderivatives',
        component: () =>
            import ('@/views/derivatives/set/setderivatives/setderivatives.vue')
    },
    {
        // 金融产品设置添加
        path: '/setderivativesadd',
        name: 'setderivativesadd',
        component: () =>
            import ('@/views/derivatives/set/setderivatives/setderivativesadd.vue')
    },
    {
        // 金融产品设置-操作记录
        path: '/setderivativeslog',
        name: 'setderivativeslog',
        component: () =>
            import ('@/views/derivatives/set/setderivatives/setderivativeslog.vue')
    },
    {
        id: 585,
        path: '/statement',
        name: 'statement',
        component: () =>
            import ('@/views/derivatives/finance/statement/statement.vue')
    },
    {
        // 合伙人列表
        id: 587,
        path: '/derpartnerlist',
        name: 'derpartnerlist',
        component: () =>
            import ('@/views/derivatives/partner/derpartnerlist/derpartnerlist.vue')
    },
    {
        // 合伙人列表-合伙人设备列表
        id: 587,
        path: '/derpartnerdvclist',
        name: 'derpartnerdvclist',
        component: () =>
            import ('@/views/derivatives/partner/derpartnerlist/derpartnerdvclist.vue')
    },
    {
        // 合同列表
        id: 588,
        path: '/dercontractlist',
        name: 'dercontractlist',
        component: () =>
            import ('@/views/derivatives/partner/dercontractlist/dercontractlist.vue')
    },
    {
        // 金融产品-合伙人-合同列表-添加合同
        id: 588,
        path: '/dercontractlistadd',
        name: 'dercontractlistadd',
        component: () =>
            import ('@/views/derivatives/partner/dercontractlist/dercontractlistadd.vue')
    },

    {
        // 合同设备列表
        id: 624,
        path: '/dercontractdvclist',
        name: 'dercontractdvclist',
        component: () =>
            import ('@/views/derivatives/partner/dercontractdvclist/dercontractdvclist.vue')
    },
    {
        // 邀请返现记录
        // id: 589,
        path: '/derpartnercash',
        name: 'derpartnercash',
        component: () =>
            import ('@/views/derivatives/partner/derpartnercash/derpartnercash.vue')
    },
    {
        // 金融产品提现记录
        id: 586,
        path: '/derwithdrawalrecord',
        name: 'derwithdrawalrecord',
        component: () =>
            import ('@/views/derivatives/finance/derwithdrawalrecord/derwithdrawalrecord.vue')
    },
    {
        // 合同终止审批
        id: 607,
        path: '/dercontractstop',
        name: 'dercontractstop',
        component: () =>
            import ('@/views/derivatives/partner/dercontractstop/dercontractstop.vue')
    },
    {
        // 合同终止审批-合同详情
        path: '/dercontractstopinfo',
        name: 'dercontractstopinfo',
        component: () =>
            import ('@/views/derivatives/partner/dercontractstop/dercontractstopinfo.vue')
    },

    {
        // 设置-会员设置-充值会员设置
        id: 611,
        path: '/rechargememberset',
        name: 'rechargememberset',
        component: () =>
            import ('@/views/set/memberset/rechargememberset.vue')
    },
    {
        // 设置-会员设置-会员开关设置
        id: 617,
        path: '/memberswitchset',
        name: 'memberswitchset',
        component: () =>
            import ('@/views/set/memberset/memberswitchset.vue')
    },
    {
        // 财务-资金对账-充值会员对账
        id: 619,
        path: '/rechargememberaccount',
        name: 'rechargememberaccount',
        component: () =>
            import ('@/views/finance/capital/rechargememberaccount/rechargememberaccount.vue')
    },
    {
        // 财务-资金对账-充值会员结算单
        id: 621,
        path: '/rechargememberstatement',
        name: 'rechargememberstatement',
        component: () =>
            import ('@/views/finance/capital/rechargememberstatement/rechargememberstatement.vue')
    },
    {
        // 财务-提现管理-对公打款
        id: 629,
        path: '/corporatetransferlist',
        name: 'corporatetransferlist',
        component: () =>
            import ('@/views/finance/withdrawal/corporatetransfer/corporatetransferlist/corporatetransferlist.vue')
    },
    {
        // 财务-提现管理-对公打款-添加
        id: 629,
        path: '/corporatetransferadd',
        name: 'corporatetransferadd',
        component: () =>
            import ('@/views/finance/withdrawal/corporatetransfer/corporatetransferadd/corporatetransferadd.vue')
    },
    {
        // 财务-提现管理-对公打款-详情
        id: 629,
        path: '/corporatetransferinfo',
        name: 'corporatetransferinfo',
        component: () =>
            import ('@/views/finance/withdrawal/corporatetransfer/corporatetransferinfo/corporatetransferinfo.vue')
    },

    {
        // 财务-提现管理-银行卡打款
        id: 647,
        path: '/corporatetransferbankcardlist',
        name: 'corporatetransferbankcardlist',
        component: () =>
            import ('@/views/finance/withdrawal/corporatetransferbankcard/corporatetransferbankcardlist/corporatetransferbankcardlist.vue')
    },
    {
        // 财务-提现管理-银行卡打款-添加
        id: 647,
        path: '/corporatetransferbankcardadd',
        name: 'corporatetransferbankcardadd',
        component: () =>
            import ('@/views/finance/withdrawal/corporatetransferbankcard/corporatetransferbankcardadd/corporatetransferbankcardadd.vue')
    },
    {
        // 财务-提现管理-银行卡打款-详情
        id: 647,
        path: '/corporatetransferbankcardinfo',
        name: 'corporatetransferbankcardinfo',
        component: () =>
            import ('@/views/finance/withdrawal/corporatetransferbankcard/corporatetransferbankcardinfo/corporatetransferbankcardinfo.vue')
    },

    {
        // 运营-余额充值-余额充值设置
        id: 653,
        path: '/surplusset',
        name: 'surplusset',
        component: () =>
            import ('@/views/operate/surplus/surplusset/surplusset.vue')
    },
    {
        // 运营-余额充值-赠品管理
        id: 642,
        path: '/postgoodslist',
        name: 'postgoodslist',
        component: () =>
            import ('@/views/operate/surplus/postgoods/postgoodslist.vue')
    },
    {
        // 运营-余额充值-赠品管理-添加
        path: '/postgoodslistadd',
        name: 'postgoodslistadd',
        component: () =>
            import ('@/views/operate/surplus/postgoods/postgoodslistadd.vue')
    },
    {
        // 运营-余额充值-赠品管理-编辑
        path: '/postgoodslistedit',
        name: 'postgoodslistedit',
        component: () =>
            import ('@/views/operate/surplus/postgoods/postgoodslistedit.vue')
    },

    {
        // 运营-商城管理-商品管理
        // id: 642,
        path: '/goodslist',
        name: 'goodslist',
        component: () =>
            import ('@/views/operate/shopping/goods/goodslist.vue')
    },
    {
        // 运营-商城管理-商品管理-添加
        path: '/goodslistadd',
        name: 'goodslistadd',
        component: () =>
            import ('@/views/operate/shopping/goods/goodslistadd.vue')
    },
    {
        // 运营-商城管理-商品管理-编辑
        path: '/goodslistedit',
        name: 'goodslistedit',
        component: () =>
            import ('@/views/operate/shopping/goods/goodslistedit.vue')
    },
    {
        // 运营-商城管理-订单管理
        // id: 642,
        path: '/ordermanage',
        name: 'ordermanage',
        component: () =>
            import ('@/views/operate/shopping/ordermanage/ordermanage.vue')
    },
    {
        // 运营-商城管理-订单管理-订单详情
        path: '/ordermanageinfo',
        name: 'ordermanageinfo',
        component: () =>
            import ('@/views/operate/shopping/ordermanage/ordermanageinfo.vue')
    },

    // -----------------------兼容家庭版---------------------------
    {
        // 打印-机器控制-家庭成员-关联用户
        path: '/sharedvccontrolfamilyedit',
        name: 'sharedvccontrolfamilyedit',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontrolfamilyedit.vue')
    },

    // -----------------------临时任务：20230227-给资金方展示---------------------------
    {
        id: 657,
        path: '/accountfd',
        name: 'accountfd',
        component: () =>
            import ('@/views/finance/capitalFunder/accountfd.vue')
    },
    {
        id: 672,
        path: '/printoverviewfd',
        name: 'printoverviewfd',
        component: () =>
            import ('@/views/census/printFunder/overviewfd.vue')
    },
    {
        id: 673,
        path: '/dvcordercensusfd',
        name: 'dvcordercensusfd',
        component: () =>
            import ('@/views/census/printFunder/dvcordercensusfd/dvcordercensusfd.vue')
    },
    {
        id: 674,
        path: '/dvcorderrankingfd',
        name: 'dvcorderrankingfd',
        component: () =>
            import ('@/views/census/printFunder/dvcorderrankingfd.vue')
    },
    {
        id: 678,
        path: '/agentcensusfd',
        name: 'agentcensusfd',
        component: () =>
            import ('@/views/census/printFunder/agentcensusfd/agentcensusfd.vue')
    },
    {
        // 统计-打印流水统计-设备收益统计
        id: 752,
        path: '/yeardvccountfd',
        name: 'yeardvccountfd',
        component: () =>
            import ('@/views/census/printFunder/yeardvccountfd/yeardvccountfd.vue')
    },
    
    // 20240206 新增
    {
        // 统计-打印流水统计-设备打印收益统计
        id: 869,
        path: '/printEarnings',
        name: 'printEarnings',
        component: () =>
          import ('@/views/census/printFunder/printEarnings/printEarnings.vue')
    },
  
    {
        // 运营-企业微信-新粉优惠券
        id: 750,
        path: '/newfanscoupon',
        name: 'newfanscoupon',
        component: () =>
            import ('@/views/operate/wecom/newfanscoupon/newfanscoupon.vue')
    },

    {
        // 合作伙伴-指定设备报单-代理商指定设备
        id: 755,
        path: '/agentappointdvc',
        name: 'agentappointdvc',
        component: () =>
            import ('@/views/collaborate/appointdvc/agent/agentappointdvc.vue')
    },
    {
        // 合作伙伴-指定设备报单-负责人指定设备
        id: 766,
        path: '/headerappointdvc',
        name: 'headerappointdvc',
        component: () =>
            import ('@/views/collaborate/appointdvc/header/headerappointdvc.vue')
    },
    {
        // 合作伙伴-指定设备报单-指定设备列表
        id: 761,
        path: '/deviceappointdvc',
        name: 'deviceappointdvc',
        component: () =>
            import ('@/views/collaborate/appointdvc/device/deviceappointdvc.vue')
    },
     // -----------------------会员权益---------------------------
    {
        // 运营-权益会员-会员列表
        id: 782,
        path: '/memberList',
        name: 'memberList',
        component: () =>
            import ('@/views/operate/memberBenefits/member/memberList.vue')
    },
    {
        // 运营-权益会员-会员设置
        id: 784,
        path: '/memberSet',
        name: 'memberSet',
        component: () =>
            import ('@/views/operate/memberBenefits/memberSet/memberSet.vue')
    },
    {
        // 运营-权益会员-商家权益-列表
        id: 785,
        path: '/shopEquity',
        name: 'shopEquity',
        component: () =>
            import ('@/views/operate/memberBenefits/shopEquity/shopEquity.vue')
    },
    {
        // 运营-权益会员-商家权益-设备设置
        // id: 761,
        path: '/deviceSet',
        name: 'deviceSet',
        component: () =>
            import ('@/views/operate/memberBenefits/shopEquity/deviceSet.vue')
    },
    {
        // 运营-权益会员-设备统计
        id: 807,
        path: '/memberDvcCount',
        name: 'memberDvcCount',
        component: () =>
            import ('@/views/operate/memberBenefits/memberDvcCount/memberDvcCount.vue')
    },
    {
        // 运营-权益会员-购买记录
        id: 806,
        path: '/memberBuyRecord',
        name: 'memberBuyRecord',
        component: () =>
            import ('@/views/operate/memberBenefits/memberBuyRecord/memberBuyRecord.vue')
    },
    {
        // 运营-权益会员-核销记录
        id: 798,
        path: '/memberWriteOff',
        name: 'memberWriteOff',
        component: () =>
            import ('@/views/operate/memberBenefits/memberWriteOff/memberWriteOff.vue')
    },
    {
        // 订单-教育管理-第三方文库订单列表
        id: 779,
        path: '/libraryorderOther',
        name: 'libraryorderOther',
        component: () =>
            import ('@/views/order/education/libraryorderOther.vue')
    },
    {
        // 统计-教育统计-第三方文库订单排行
        id: 780,
        path: '/libraryorderSortOther',
        name: 'libraryorderSortOther',
        component: () =>
            import ('@/views/census/education/libraryorderSortOther.vue')
    },
    {
        // 订单-打印管理-超期退款订单提交
        id: 794,
        path: '/orderOverdueRefund',
        name: 'orderOverdueRefund',
        component: () =>
            import ('@/views/order/print/orderOverdueRefund/orderOverdueRefund.vue')
    },
    // ***************打印新粉统计***************
    {
        // 统计-打印统计-打印新粉统计
        id: 811,
        path: '/newFansCount',
        name: 'newFansCount',
        component: () =>
            import ('@/views/census/print/newFansCount/newFansCount.vue')
    },
    {
        // 运营-公众号-公众号配置
        id: 814,
        path: '/pubNumberSet',
        name: 'pubNumberSet',
        component: () =>
            import ('@/views/operate/pubNumber/pubNumberSet/pubNumberSet.vue')
    },
    {
        // 运营-公众号-禁用设备
        id: 815,
        path: '/pubNumberDisDvc',
        name: 'pubNumberDisDvc',
        component: () =>
            import ('@/views/operate/pubNumber/pubNumberDisDvc/pubNumberDisDvc.vue')
    },
    {
        // 运营-公众号-渠道推广链接
        id: 825,
        path: '/channelSpreadLink',
        name: 'channelSpreadLink',
        component: () =>
            import ('@/views/operate/pubNumber/channelSpreadLink/channelSpreadLink.vue')
    },
    {
        // 运营-公众号-回复消息配置
        id: 831,
        path: '/backNewsSet',
        name: 'backNewsSet',
        component: () =>
            import ('@/views/operate/pubNumber/backNewsSet/backNewsSet.vue')
    },
    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
